import React from "react";
import PropTypes from "prop-types";
import { Link } from "gatsby";

String.prototype.capitalize = function(lower) {
  return (lower ? this.toLowerCase() : this).replace(/(?:^|\s)\S/g, function(a) {
    return a.toUpperCase();
  });
};

const Breadcrumbs = props => {
  const { slug, title, theme } = props;
  const path = slug;

  const seperator = <span> / </span>;

  // Inserting a "/" between each directory in the path and linking up all but the last one
  const pathBits = path.split("/").filter(function(n) {
    return n != "";
  });
  let breadcrumbs = [],
    pathBit = {},
    pathSoFar = "";

  for (let i = 0; i < pathBits.length; i++) {
    pathBit = pathBits[i].replace("-", " ").capitalize(true);

    if (i < pathBits.length - 1) {
      pathBit = (
        <Link to={"/" + pathSoFar + pathBits[i] + "/"} key={i}>
          {pathBit}
        </Link>
      );
      breadcrumbs.push(pathBit, seperator);
    } else {
      if (title) {
        pathBit = <span key={i}>{title}</span>;
      } else {
        pathBit = <span key={i}>{pathBit}</span>;
      }
      breadcrumbs.push(pathBit);
    }

    pathSoFar += pathBits[i] + "/";
  }

  return (
    <React.Fragment>
      <div className="Breadcrumbs">
        <header className="header" role="banner">
          <nav className="navigation" role="navigation">
            <h1 className="inline">
              <Link to={"/"}>
                <strong>Econtechie</strong>
              </Link>
              {seperator}
              {breadcrumbs}
            </h1>
          </nav>
          <br />
        </header>
      </div>
      <style jsx>{`
        * {
          :global(h1) {
            line-height: ${theme.font.lineHeight.s};
            font-size: ${theme.font.size.xxs};
          }

          :global(h3) {
            font-size: ${theme.font.size.m};
            line-height: ${theme.font.lineHeight.m};
          }

          :global(p) {
            font-size: ${theme.font.size.m};
            line-height: ${theme.font.lineHeight.xxl};
            margin: 0 0 1.5em;
          }
          :global(ul) {
            list-style-type: disc;
            margin: 0 0 1.5em;
            padding: 0 0 0 1.5em;
          }
          :global(li) {
            margin: 0.7em 0;
            line-height: 1.5;
          }
          :global(a) {
            font-weight: ${theme.font.weight.bold};
            color: ${theme.color.brand.primary};
            text-decoration: underline;
          }
        }
      `}</style>
    </React.Fragment>
  );
};

Breadcrumbs.propTypes = {
  slug: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  theme: PropTypes.object.isRequired
};

export default Breadcrumbs;
